import { Link } from "@reach/router";
import { validateEmail } from "../utils";
import React, { useState, useContext } from "react";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";

const Welcome = () => {
  const [email, setEmail] = useState("");
  const { alert } = useContext(ModalContext);
  const { postSignUp } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return alert("Lo sentimos, ingresa un email válido.");
    }
    postSignUp(email);
  };

  return (
    <div
      style={{ backgroundImage: `url("/images/background.png")` }}
      className="container-fluid px-3 px-md-5"
    >
      <nav className="navbar align-items-center bg-body-tertiary px-3 px-md-5">
        <a className="navbar-brand" href="#">
          <img
            src="/images/logo.png"
            style={{ maxWidth: 250 }}
            className="w-100 hide-mobile m-auto"
          />
          <img
            src="/images/isologo.png"
            style={{ maxWidth: 50 }}
            className="w-100 show-mobile m-auto"
          />
        </a>
        <Link
          to="/auth"
          className="btn btn-light ms-auto me-0 px-4 text-primary border-primary"
        >
          <i className="fa fa-user me-1"></i> Log In
        </Link>
      </nav>
      <div className="row align-items-center pt-5 py-md-5 vh-100">
        <div className="col-12 col-md-12 col-lg-4 py-md-5">
          <h1 className="bold display-2">Effortless, Cashless Parking.</h1>
          <p className="h5">
            El nuevo estándar de pagos para estacionamientos.
          </p>
          <div className="input-group bg-white border-primary pe-0 mb-md-3">
            <input
              type="email"
              value={email}
              className="form-control"
              aria-describedby="button-addon2"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Escribe tu email"
              aria-label="Escribe tu email"
            />
            <button
              className="btn btn-lg btn-light px-4 border border-primary text-primary"
              onClick={handleSubmit}
              id="button-addon2"
              type="button"
            >
              Comenzar
            </button>
          </div>{" "}
        </div>
        <div className="col-12 col-md-12 col-lg-8">
          <img src="/images/mockup.png" className="mw-100 w-100 ms-auto me-0" />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
